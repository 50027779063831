#about {
    padding: 2rem 0;
    background-color: $primary-color-300;

    .section-heading {
        h2 {
            &::after {
                content: 'About Me';
                position: absolute;
                background-color: $primary-color-300;
            }
        }
    }

    .content {
        padding: .5rem 1rem;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .paragraphs {
            color: #fff;
            width: 50%;

            p {
                &:first-child {
                    padding-top: 0;
                }

                padding: .75rem .5rem;
            }

            a {
                margin-top: 1.25rem;
                margin-left: .5rem;
                background-color: darken($secondary-color-200, 10%);
                background: linear-gradient(darken($secondary-color-200, 10%), darken($secondary-color-200, 11%));
                color: $primary-color-200;
                text-decoration: none;
                padding: .5rem 2rem;
                border-radius: 50px;
                display: inline-block;
                transition: .5s ease-in-out;

                &:hover {
                    background-color: darken($secondary-color-200, 15%);
                    background: linear-gradient(darken($secondary-color-200, 15%), darken($secondary-color-200, 18%));
                }
            }
        }

        .cube {
            width: 350px;
            height: 350px;
            position: relative;
            bottom: 50px;
            right: 50px;
        }
    }

    //  Tablets  //
    @media screen and (max-width: 820px) {
        .content {
            position: relative;

            .cube {
                position: absolute;
                right: -20px;
                top: -150px;
            }

            .paragraphs {
                width: 80%;
            }
        }

    }

    //  Small Screens  //
    @media screen and (max-width: 700px) {
        padding: 2rem .5rem;
        // .section-heading {
        //   h2 {
        //     margin: 0 auto;
        //   }
        // }

        .content {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0;

            .paragraphs {
                width: 85%;
            }

            .cube {
                display: none;
            }
        }
    }

    //  Desktop Screens  //
    @media screen and (min-width: 1320px) {
        padding-left: 0;
    }


    //  Extra Large Desktop Screens  //
    @media screen and (min-width: 1800px) {
        padding-left: 0;
        padding-right: 0;
    }
}