#algos {
    padding: 2rem 0;
    background-color: $primary-color-200;
    color: #fff;

    .section-heading {
        h2 {
            &::after {
                content: 'Algorithms';
                position: absolute;
                background-color: $primary-color-200;
            }
        }
    }

    h3 {
        font-size: 30px;
        // color: $primary-color-200;
        margin-bottom: 24px;
        text-align: center;
    }

    .array-container {
        width: fit-content;
    }

    .search-container {
        display: flex;
        width: 185px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .search-buttons-container {
        max-width: 200px;
        margin: 0 auto;
    }

    .search-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        background-color: $secondary-color-100;
        color: $primary-color-300;
        width: 30px;
        padding: 5px;

        &:hover {
            cursor: pointer;
            background-color: lighten($primary-color-100, 10%);
            color: #fff;
        }

        &.current {
            background-color: #6f667f;
            color: #fff;
        }
        &.needle {
            background-color: #5e27b6;
            color: #fff;
        }
        &.needle.found {
            background-color: #7725fa;
        }
    }

    .item {
        display: inline-block;
        margin: 0 2px;
        background-color: $secondary-color-100;
        width: 14px;
        height: 20px;
    }

    .i1 {
        height: 25px;
    }

    .i2 {
        height: 30px;
    }

    .i3 {
        height: 35px;
    }

    .i4 {
        height: 40px;
    }

    .i5 {
        height: 45px;
    }

    .i6 {
        height: 50px;
    }

    .i7 {
        height: 55px;
    }

    .i8 {
        height: 60px;
    }

    .i9 {
        height: 65px;
    }

    .i10 {
        height: 70px;
    }

    .check-swap {
        font-weight: bold;
        color: #fff;
        background-color: $yellow;
        background-color: lighten($primary-color-100, 25%);
    }

    button {
        background-color: #e0dae6;
        color: $primary-color-300;

        border: none;
        padding: 6px 22px;
        border-radius: 2px;
        font-size: 15px;
        transition: .2s ease-in-out;

        &:hover {
            cursor: pointer;
            color: $primary-color-300;
            background-color: $secondary-color-200;
        }
    }

    .algorithms-container {
        margin-top: 20px;
    }
    .algorithms-container .row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 45px;
        justify-content: space-around;
    }
    .algorithms-container .algo {
        border: 1px solid $secondary-color-200;
        padding: 50px;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .buttons-container {
        margin-top: 40px;
    }
    .buttons-container button:first-child {
        margin-right: 28px;
    }

    canvas {
        cursor: pointer;
    }

    // Mobile
    @media (max-width: 768px) {
        .algorithms-container .row {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 0;
        }
        .algorithms-container .algo {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 45px;
        }
    }
}