#professional-projects {
  .section-heading {
    h2 {
      width: 340px;
      margin-bottom: 1.5rem;
      &::after {
        width: 320px;
        content: 'Professional Projects';
      }
    }
  }

  .projects-container {
    @mixin thumbnail-styles {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .project-card:first-child .thumbnail {
      background: url('../../images/investing-lynx.png');
      @include thumbnail-styles;
    }

    .project-card:nth-child(2) .thumbnail {
      background: url('../../images/brave-submarines.png');
      @include thumbnail-styles;
    }

    .project-card:last-child .thumbnail {
      background: url('../../images/thelotusandthelion.png');
      @include thumbnail-styles;
    }

    .project-card .thumbnail .overlay h3 {
      font-size: 1.5rem;
    }

    .project-card {
      .details .buttons-container {
        a {
          width: 80%;
        }
        a:last-child {
          display: none;
        }
      }
      
    }
  }
}