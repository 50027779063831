#introduction {
  background: linear-gradient($primary-color-100, $primary-color-200);
  padding-bottom: 3.5rem;

  .github-logo {
    width: 40px;
    position: relative;
    // left: 2rem;
    // top: 1.5rem;
    left: 0;
    top: 40px;

    img {
      max-width: 100%;
      opacity: .8;

      &:hover {
        opacity: 1;
      }
    }
  }

  .top-container {
    margin-bottom: 3.5rem;
    padding-bottom: 2.5rem;
    padding-top: 0;

    .heading-container {
      h1 {
        padding-bottom: .2rem;
        font-size: 3.5rem;
      }

      p {
        font-size: 1.2rem;
        font-weight: 300;
        color: rgba(255, 255, 255, .9);
      }
    }

    .cube {
      width: 300px;
      height: 300px;
      position: relative;
      top: 15px;
      right: 100px;
      background-color: rgba(255, 255, 255, 0);
    }
  }

  .heading-and-cube {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tools-heading {
    font-size: 2rem;
    margin-bottom: .5rem;
    font-weight: 400;
    text-align: center;
  }

  .tools {
    background-color: $secondary-color-100;
    width: 100%;
    padding: 1.5rem;

    img {
      min-width: 80px;
      max-width: 150px;
      height: 80px;
      margin: 0 1.5rem;
      transition: 0.1s ease-in;

      &:hover {
        transform: scale(1.055);
      }
    }
  }

  //  Tablets  // 
  @media screen and (max-width: 820px) {
    .top-container {
      .cube {
        right: 0;
      }
    }
  }

  //  Small Screens  //
  @media screen and (max-width: 700px) {
    .github-logo {
      left: 1.3rem;
    }

    .heading-and-cube {
      margin-top: 1.8rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2.5rem 1.3rem;
    }

    .top-container {
      padding-bottom: 0;

      .heading-container {
        h1 {
          padding-bottom: .2rem;
          font-size: 2rem;
        }
  
        p {
          font-size: 1rem;
        }
      }

      .cube {
        top: 15px;
        right: 0;
      }
    }

    .tools-heading {
      font-size: 1.5rem;
    }

    .tools {
      padding: 1rem;

      .text-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      img {
        min-width: 30px !important;
        max-width: 50px !important;
        height: auto;
        margin: 5px 12px;
      }
    }
  }


  //  Desktop Screens  //
  @media screen and (min-width: 1320px) {
    // .github-logo {
    //   left: 5%;
    // }

    // .top-container {
    //   padding-left: 5%;
    // }
  }


  //  Extra Large Desktop Screens  // 
  @media screen and (min-width: 1800px) {
    .github-logo {
      left: 0;
      top: 40px;
    }

    .top-container {
      padding: 0 0 2.5rem 0;
    }
  }
}