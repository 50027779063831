.projects {
  background-color: $primary-color-300;
  position: relative;
  overflow: hidden;
  padding: 1.5rem 0;
  padding-bottom: 2rem;

  .background-design {
    position: absolute;
    top: 35%;
    z-index: 1;
    width: 100%;
    border: 1px solid #fff;
    height: 300px;
    opacity: .6;
    animation: blink 2s ease-in-out 2.5s infinite alternate forwards;

    &::before {
      content: "";
      position: absolute;
      top: -15%;
      z-index: 0;
      width: 97%;
      margin: 0 1.5%;
      border: 1px solid $accent-color;
      height: 300px;
    //   animation: blink 2s ease-in-out 1.5s infinite alternate forwards;
    }

    &::after {
      content: "";
      position: absolute;
      top: 15%;
      z-index: 0;
      width: 97%;
      margin: 0 1.5%;
      border: 1px solid $accent-color;
      height: 300px;
    //   animation: blink 2s ease-in-out infinite alternate forwards;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }

  .projects-container {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 2rem;

    .project-card {
      max-width: 400px;
      width: 25%;
      height: 500px;
      background-color: darken($secondary-color-200, 5%);
      border-radius: 4px;
      border: 2px solid rgb(32, 32, 32);
      box-shadow: 0 0 8px #9b82bf31;
      padding: 4px;
      overflow: hidden;
      transition: transform 0.2s ease-out;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 12px #9b82bf56;
      }

      .thumbnail {
        height: 180px;

        .overlay {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.60);

          h3 {
            font-size: 1.5rem;
          }
        }
      }

      .short-description {
        background-color: rgb(26, 26, 26);
        text-align: center;
        padding: .8rem;
      }

      .details {
        padding: 1rem;

        .buttons-container {
          text-align: center;
          display: flex;
          justify-content: space-around;
          padding-bottom: 1rem;

          a {
            display: inline-block;
            padding: .4rem 1rem;
            min-width: 120px;
            border: 1px solid #4A18BC;
            color: #4A18BC;
            border-radius: 20px;
            text-decoration: none;
            background-color: rgba(247, 247, 247, 0);

            &:hover {
              background-color: adjust-color($accent-color, $alpha: -0.9);
            }
          }
        }

        .technologies {
          color: #000;
          font-weight: 500;
          padding: 1rem 1rem 1rem 1rem;
          border-top: 1px solid rgb(189, 189, 189);
          border-bottom: 1px solid rgb(189, 189, 189);
        }

        .full-description {
          color: rgb(34, 34, 34);
          padding: 1rem 1rem 0 1rem;
        }
      }
    }
  }


  //  Tablets  //
  @media screen and (max-width: 820px) {
    .projects-container {
      .project-card {
        width: 32%;
      }

      .overlay h3 {
        font-size: 1.5rem !important;
      }

      .short-description {
        font-size: .8rem !important;
      }

      .buttons-container {
        font-size: .8rem;

        a {
          min-width: 100px !important;
        }
      }

      .full-description {
        font-size: .9rem;
      }
    }
  }

  //  Small Screens  //
  @media screen and (max-width: 700px) {
    .background-design {
      display: none;
    }

    .projects-container {
      width: 80%;
      max-width: 350px;
      margin: 0 auto;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .project-card {
        width: 100%;
        margin-bottom: 60px;
      }

      .project-card:last-child {
        margin-bottom: 10px;
      }

      .overlay h3 {
        font-size: 1.5rem !important;
      }

      .short-description {
        font-size: 1rem !important;
      }

      .buttons-container {
        font-size: .9rem;

        a {
          min-width: 120px !important;
        }
      }

      .full-description {
        font-size: 1rem;
      }
    }
  }

  //  Large Desktop Screens  //
  @media screen and (min-width: 1500px) {
    .projects-container {
      width: 95%;
      margin: 0 auto;

      .project-card {
        width: 22%;
      }
    }
  }

  //  Extra Large Desktop Screens  //
  @media screen and (min-width: 1800px) {
    .projects-container {
      width: 90%;

      .project-card {
        width: 21%;
      }
    }
  }

}