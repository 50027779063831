#personal-projects {

    .section-heading {
        h2 {
            width: 300px;
            margin-bottom: 1.5rem;

            &::after {
                width: 280px;
                content: 'Personal Projects';
            }
        }
    }

    .projects-container {
        @mixin thumbnail-styles {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .project-card:first-child .thumbnail {
            background: url('../../images/level-up-live.png');
            @include thumbnail-styles;
        }

        .project-card:nth-child(2) .thumbnail {
            background: url('../../images/graphmaker-screenshot.png');
            @include thumbnail-styles;
        }

        .project-card:last-child .thumbnail {
            background: url('../../images/poketeambuilder-screenshot.png');
            @include thumbnail-styles;

        }
    }
}