@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

//  Global Variables  //
$primary-color-100: #3A0A86;
$primary-color-200: #1D0543;
$primary-color-300: #11071F;
$secondary-color-100: #F4F4F4;
$secondary-color-200: #EDEDED;
$accent-color: #9B82BF;
$primary-font-family: 'Roboto', sans-serif;

$yellow: #e4df74;
$green: #339a33;

//  Global Styles  //
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    overflow: hidden;
}

.center {
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

body {
    font-family: $primary-font-family;
    color: #fff;
}

.section-heading {
    padding: 2rem;
    font-size: 1.3rem;

    h2 {
        border: 1px solid #fff;
        text-align: center;
        width: 250px;
        padding: .9rem;
        position: relative;
        z-index: 20;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            right: 10px;
            bottom: 10px;
            border: 1px solid #fff;
            text-align: center;
            width: 220px;
            padding: .8rem;
            background-color: $primary-color-300;
        }
    }
}

.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1500px;
}

/*
    Flex classes
*/
.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.justify-center {
    justify-content: center;
}

.space-around {
    justify-content: space-around;
}

.justify-around {
    justify-content: space-around;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.nowrap {
    flex-wrap: nowrap;
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 1;
}

.flex-grow-0 {
    flex-grow: 0;
}

// Small screens
@media screen and (max-width: 700px) {
    .section-heading {
        h2 {
            margin: 0 auto;
        }
    }

    .container {
        width: 95%;
    }
}

//  Parials  //
@import './components/introduction.scss';
@import './components/algos.scss';
@import './components/projects.scss';
@import './components/personal-projects.scss';
@import './components/professional-projects.scss';
@import './components/about.scss';
@import './components/contact.scss';