#contact {
  background-color: $primary-color-200;
  padding: 2rem 3rem;
  padding-bottom: 3rem;

  .container {
    display: flex;
    justify-content: space-around;

    .contact-container {
      .section-heading {
        h2 {
          margin-bottom: 0;
          &::after {
            content: 'Contact Me';
            background-color: $primary-color-200;
          }
        }
      }

      form {
        padding-left: 2rem;
        width: 280px;

        .form-input-container {
          padding: 10px 0;

          label, input, textarea {
            width: 250px;
            display: block;
          }

          input, textarea {
            padding: 5px;
            margin-top: 6px;
            font-size: 1rem;
            font-family: $primary-font-family;
          }
        }

        button {
          float: right;
          border-radius: 50px;
          padding: .25rem 1rem;
          font-size: .95rem;
          border: 1px solid gray;
          background-color: rgb(233, 233, 233);
          color: $primary-color-200;

          &:hover {
            cursor: pointer;
            background-color:rgb(221, 221, 221);
          }
        }
      }
    }

    .socials-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .section-heading {
        h2 {
          margin-bottom: 1.5rem;
          &::after {
            content: 'Socials';
            background-color: $primary-color-200;
          }
        }
      }

      .social-logos {
        margin: 0 2rem;
        width: 310px;
        padding: 10px 20px;
        background-color: $secondary-color-200;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 70px;
          margin: 0 6px;

          &:hover {
            opacity: .9;
          }
        }
      }

      .social-logos a:last-child {
        img {
          position: relative;
          top: 4px;
          width: 90px;
        }
      }

      .codewars-badge {
        margin-top: 50px;

        img {
          max-width: 280px;
        }
      }
    }
  }


  //  Small Screens  //
  @media screen and (max-width: 700px) {

    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contact-container {
        padding-bottom: 2rem;
      }
    }
  }

  //  Desktop Screens  //
  @media screen and (min-width: 1320px) {
    .contact-container {
      width: 30%;
      max-width: 450px;

      .section-heading {
        width: 100%;

        h2 {
          width: 100%;
          padding: 1.2rem;

          &:after {
            width: 89%;
            padding: 1.1rem;
            right: 12px;
            bottom: 12px;
          }
        }
      }

      form {
        width: 90% !important;

        .form-input-container {
          input, label, textarea {
            width: 100% !important;
          }
        }
      }
    }

    .socials-container {
      width: 30%;
      max-width: 450px;

      .section-heading {
        width: 100%;

        h2 {
          width: 100%;
          padding: 1.2rem;

          &:after {
            width: 89%;
            padding: 1.1rem;
            right: 12px;
            bottom: 12px;
          }
        }
      }

      .social-logos {
        width: 90% !important;
        justify-content: space-between !important;
      }

      .codewars-badge {
        max-width: 100% !important;
        margin-left: 5px;
        text-align: center;
        img {
          max-width: 90% !important;
        }
      }
    }
  }
}